<template>
  <div class="space-y-10 smrt_stations">
    <asom-card>
      <div class="grid sm:grid-cols-1 md:grid-cols-2 md:gap-x-6 gap-4">
        <asom-form-field label="Date Range" required>
          <div class="grid sm:grid-cols-1 md:grid-cols-2 md:gap-x-6 gap-4">
            <asom-input-date-time v-model="filters.dateRange.start" />
            <asom-input-date-time v-model="filters.dateRange.end"/>
          </div>
        </asom-form-field>
        <asom-form-field label="File name">
          <asom-input-text v-model="filters.fileName" />
        </asom-form-field>
      </div>
      <template #footer>
        <asom-button
          text="Reset Filters"
          variant="secondary"
          @click="resetFilters"
        />
        <asom-button text="Apply" @click="loadData(true)" />
      </template>
    </asom-card>
    
    <div class="w-full text-center" v-if="isLoading">
      <asom-icon icon="spinner" class="animate-spin" />
    </div>
    <template v-else>
      <asom-client-table
        :columns="['fileName', 'dateCreated', 'error']"
        :data="tableData"
        :sortableColumns="['fileName', 'dateCreated', 'error']"
      >
        <template v-slot:dateCreated="scopedSlots">{{displayUtcDateTime(scopedSlots.data)}}</template>
      </asom-client-table>
      <h2 class="Asom__Component_Title max-w-4xl font-semibold ml-3">
        SAP Import Files
      </h2>
      <asom-client-table
        :columns="['fileName', 'dateCreated', 'action']"
        :data="files"
        :sortableColumns="['fileName', 'dateCreated', 'action']"
      >
        <template v-slot:header_dateCreated>Date Imported</template>
        <template v-slot:fileName="scopedSlots">
          <a class="text-blue-700 font-semibold underline cursor-pointer" @click="onFileClick(scopedSlots.rowData)">{{scopedSlots.data}}</a>
        </template>
        <template v-slot:dateCreated="scopedSlots">{{displayUtcDateTime(scopedSlots.data)}}</template>
        <template v-slot:action="scopedSlots">
          <asom-button @click="viewFileLogs(scopedSlots.rowData)" text="View all logs" />
        </template>
      </asom-client-table>
    </template>

  </div>
</template>

<script>
import get from "lodash.get";
import moment from 'moment';
import { parseDateTimeToUtc, displayUtcDateTime } from "@/helpers/dateTimeHelpers";
import { GET_SAP_SYNC_ERRORS, GET_SAP_FILES } from "@/constants/apis";
import { getAsync } from '@/services/serviceHandlers';
import { downloadFile } from "../../services/file.service";
import { setStorage, getStorage } from "@/helpers/sessionStorage";

export default {
  name: "SAP Sync Error",
  data() {
    return {
      filters: {
        dateRange: {
          end: moment().toDate(),
          start: moment()
            .add(-3, "days")
            .toDate(),
        },
        fileName: '',
      },
      officerOptions: [],
      tableData: [],
      files: [],
      isLoading: false,
      error: null,
    };
  },
  mounted() {
    const searchVal = getStorage("sap-sync-errors");
    if(searchVal) {
      // UTC时间转换成对应的本地时间
      if(searchVal.dateRange && searchVal.dateRange.start)  searchVal.dateRange.start = moment.utc(searchVal.dateRange.start).toDate();
      if(searchVal.dateRange && searchVal.dateRange.end) searchVal.dateRange.end = moment.utc(searchVal.dateRange.end).toDate();
      this.filters = searchVal;
    }
    this.$nextTick(() => {
      this.loadData();
    })
  },
  computed: {
    queryParams() {
      let params = {
        skip: 0,
        take: 100,
      };
      if (
        this.filters.dateRange &&
        this.filters.dateRange.start &&
        this.filters.dateRange.end
      ) {
        params["startDate"] = parseDateTimeToUtc(this.filters.dateRange.start, true);
        params["endDate"] = parseDateTimeToUtc(this.filters.dateRange.end, true);
      }
      if (this.filters.fileName) {
        params["search"] = this.filters.fileName.trim();
      }
      return params;
    },
  },
  methods: {
    displayUtcDateTime,
    loadData(status) {
      if(status) setStorage("sap-sync-errors", this.filters);
      this.loadLogs();
      this.loadFiles();
    },
    async loadLogs() {
      this.isLoading = true;
      const resp = await getAsync({
        url: GET_SAP_SYNC_ERRORS,
        params: this.queryParams,
      });
      if (resp.success) {
        this.tableData = get(resp, "payload.list", []);
      } else {
        this.error = resp.payload;
        this.isLoading = false;
        this.$scrollTop();
        return;
      }
      this.isLoading = false;
    },
    async loadFiles() {
      const resp = await getAsync({
        url: GET_SAP_FILES,
        params: this.queryParams,
      });
      if (resp.success) {
        this.files = get(resp, "payload.list", []);
      } else {
        this.files = [];
      }
    },
    resetFilters() {
      this.filters.dateRange = {
          end: moment().toDate(),
          start: moment()
            .add(-3, "days")
            .toDate(),
      };
      this.filters.fileName = '';
    },
    async onFileClick({ attachmentId, fileName }) {
      const response = await downloadFile({
        fileId: attachmentId,
      });
      if (response) {
        var type = "text/csv";
        var file = new Blob([response.data], {
          type: type,
        });
        var fileURL = URL.createObjectURL(file);
        var fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.download = fileName;
        fileLink.click();
      } else return false;
    },
    viewFileLogs({ attachmentId }) {
      this.$router.push({
        name: 'SAP Sync Errors By File',
        params: {
          id: attachmentId
        }
      })
    }
  },
};
</script>
